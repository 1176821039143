import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Label, Input } from "reactstrap";

import { ToastContainer, toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import api from '../../services/api';

import Paises from '../QGRFuzileiro/nome_pais.json';
import EstadosCidades from '../QGRFuzileiro/cidades.json';
import Breadcrumbs from '../../components/Common/Breadcrumb';

class VisitarQgr extends Component {
    constructor(props) {
        super(props);
        this.state={
            estado: [],
            cidades: [],
            cidadeSelecionada: '',
            bairro: '',
            pais: ''
        }
    }

    informarEstado = (event) => {
        const estadoSelecionado = event.target.value;
        this.state.estado = estadoSelecionado;
        this.setState({
            estado: estadoSelecionado
        });
        this.exibirCidades(estadoSelecionado);
    }
    
    exibirCidades = (estadoSelecionado) => {
        const { estados } = EstadosCidades;
        estados.map((estado) => {
          if (estado.sigla === estadoSelecionado) {
            this.setState({
                cidades: estado.cidades
            });
          }
        });
    }

    setPais = (text) => {
        const paisSelecionado = text;
        this.state.pais = paisSelecionado;
        this.setState({
            pais: paisSelecionado
        });
    }

    setCidadeSelecionada = (event) => {
        this.state.cidadeSelecionada = event.target.value;
    }

    setBairro = (event) => {
        this.state.bairro = event.target.value;
    }

    async cadastrarVisita () {
        let objUsuario = {};
        if (localStorage.getItem("authUser")) {
            objUsuario = JSON.parse(localStorage.getItem("authUser"));
        }

        const paisSelecionado = document.getElementById('select-pais').value;
        let estadoSelecionado = '';
        let cidadeSelecionada = ''

        if (paisSelecionado === 'Brasil') {
            estadoSelecionado = document.getElementById('estado').value;
            cidadeSelecionada = document.getElementById('cidade').value;
        } else {
            estadoSelecionado = document.getElementById('estadoExterior').value;
            cidadeSelecionada = document.getElementById('cidadeExterior').value;
        }

        const novaVisita= {
            pais: paisSelecionado,
            estado: estadoSelecionado,
            cidade: cidadeSelecionada,
            bairro: document.getElementById('bairro').value,
        }


        if (!novaVisita.estado || novaVisita.estado === 'Selecionar estado') {
            toast.error("Escolha um estado.");
            return;
        }

        if (!novaVisita.cidade || novaVisita.estado === 'Selecionar cidade') {
            toast.error("Escolha uma cidade.");
            return;
        }

        if (!novaVisita.bairro) {
            toast.error("Bairro ou região, obrigatório.");
            return;
        }

        try {
            const options = {
                headers: {"Authorization" : `Bearer ${objUsuario.token}`}
            }

            await api.post('/visitas', novaVisita, options)
            .then(() => {
                window.location.href = ("/qgr", "visitarqgr");
                toast.success("Cadastro efetuado.");
            });
        } catch (error) {
            const { status } = error.response;
            if (status === 401) {
                window.location.href = ("/qgr", "visitarqgr");
            }
            toast.error("Não foi possivel cadastrar sua visita. Tente novamente!");
        }
    }

    render()  {
        return (
            <React.Fragment>
                <ToastContainer />
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Quero Visitar um QGR" breadcrumbItems={this.state.breadcrumbItems} />

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
              
                                    <Row className="mb-3">
                                        <Label className="col-md-2 col-form-label">País</Label>
                                        <Col md={10}>
                                            <Typeahead
                                                inputProps={{ id : 'select-pais', autocomplete: 'naocarregar' }}
                                                clearButton
                                                id="select-pais"
                                                labelKey="name"
                                                style={{ color: 'white !important' }}
                                                onChange={(text) => { this.setPais(text[0])}}
                                                options={Paises}
                                                placeholder="Digite seu país"
                                            />
                                        </Col>
                                    </Row>
                                    { this.state.pais === '' ? (
                                        <>
                                        </>
                                    ) : (
                                    <>
                                        { this.state.pais === 'Brasil' ? (
                                            <>
                                                <Row className="mb-3">
                                                    <Label className="col-md-2 col-form-label">Estado</Label>

                                                    <Col md={10}>
                                                        <select className="form-control" onChange={this.informarEstado} id="estado">
                                                            <option value="">Selecionar estado</option>
                                                            <option value="AC">Acre</option>
                                                            <option value="AL">Alagoas</option>
                                                            <option value="AP">Amapá</option>
                                                            <option value="AM">Amazonas</option>
                                                            <option value="BA">Bahia</option>
                                                            <option value="CE">Ceará</option>
                                                            <option value="DF">Distrito Federal</option>
                                                            <option value="ES">Espírito Santo</option>
                                                            <option value="GO">Goiás</option>
                                                            <option value="MA">Maranhão</option>
                                                            <option value="MT">Mato Grosso</option>
                                                            <option value="MS">Mato Grosso do Sul</option>
                                                            <option value="MG">Minas Gerais</option>
                                                            <option value="PA">Pará</option>
                                                            <option value="PB">Paraíba</option>
                                                            <option value="PR">Paraná</option>
                                                            <option value="PE">Pernambuco</option>
                                                            <option value="PI">Piauí</option>
                                                            <option value="RJ">Rio de Janeiro</option>
                                                            <option value="RN">Rio Grande do Norte</option>
                                                            <option value="RS">Rio Grande do Sul</option>
                                                            <option value="RO">Rondônia</option>
                                                            <option value="RR">Roraima</option>
                                                            <option value="SC">Santa Catarina</option>
                                                            <option value="SP">São Paulo</option>
                                                            <option value="SE">Sergipe</option>
                                                            <option value="TO">Tocantins</option>
                                                            <option value="EX">Estrangeiro</option>
                                                        </select>
                                                    </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                    <Label className="col-md-2 col-form-label">Cidade</Label>
                                                    <Col md={10}>
                                                        <select className="form-control" onChange={this.setCidadeSelecionada} id="cidade">
                                                            <option value="">Selecionar cidade</option>
                                                            { this.state.cidades.length > 1 ? (
                                                                <>
                                                                {this.state.cidades.map((cidade) => {
                                                                    return (
                                                                    <>
                                                                        <option key={`${cidade}`} value={`${cidade}`}>{`${cidade}`}</option>
                                                                    </>
                                                                    )
                                                                })}
                                                                </>
                                                            ) : ( <></> )}
                                                        </select>
                                                    </Col>
                                                </Row>
                                            </>
                                        ) : (
                                            <>
                                                <Row className="mb-3">
                                                    <Label htmlFor="example-text-input" className="col-md-2 col-form-label">Estado</Label>
                                                    <Col md={10}>
                                                        <Input type="text" placeholder="Digite o seu estado" onChange={this.setEstadoInput} id="estadoExterior"/>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Label htmlFor="example-text-input" className="col-md-2 col-form-label">Cidade</Label>
                                                    <Col md={10}>
                                                        <Input type="text" placeholder="Digite a sua cidade" onChange={this.setCidadeInput} id="cidadeExterior"/>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                        
                                        <Row className="mb-3">
                                            <Label className="col-md-2 col-form-label">Bairro / Região</Label>
                                            <Col md={10}>
                                                <Input type="text" placeholder=" Digite seu bairro ou região" onChange={this.setBairro} id="bairro"/>
                                            </Col>
                                        </Row>
                                    </>
                                    )}
                                        <div className="d-grid mb-2">
                                            <Button
                                                color="primary"
                                                className="btn btn-success btn-lg btn-block"
                                                onClick={this.cadastrarVisita}
                                            >
                                                CADASTRAR
                                            </Button>
                                        </div>
                                    
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    </Container> 
                </div>
            </React.Fragment>
        );
    }
        }

export default VisitarQgr;













